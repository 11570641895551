/* YourTableComponent.css */
.table-container {
    overflow-x: auto; /* Enable horizontal scrolling if content overflows */
    padding-left: 10px;
    padding-right: 10px;
  }
  .wrapper {
    background-color: #deedd3;
  }
  .dynamic-width-table {
    width: 100%;
    table-layout: auto; /* Columns have dynamic width based on content */
    border-collapse: collapse;
  }
  
  .dynamic-width-table th,
  .dynamic-width-table td {
    border: 1px solid #000000;
    text-align: left;
  }
  
  /* Optional: Add styling for header background color, text color, etc. */
  .dynamic-width-table th {
    text-decoration: none;
  }
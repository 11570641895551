.gallery-container {
    display: flex;
    justify-content: center;
    gap: 16px; /* Adjust the gap between photos as needed */
    padding-left: 5px;
    padding-top: 20px;
    background-color: white;
    min-height: 100vh;
  }

  .photo-item img {
    max-width: 30vw;
    max-height: 30vw;
    min-height: 300px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
  }
  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .photo-item {
    flex: 0 0 calc(50% - 20px); /* 50% width with some margin */
    margin: 10px;
    max-width: 400px; /* Adjust the maximum width as needed */
  }
  
  @media (max-width: 768px) {
    .photo-item {
      flex: 0 0 calc(100% - 20px); /* Full width with some margin on smaller screens */
    }
  }
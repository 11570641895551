.container {
  text-align: center;
  background-color: white;
  min-height: 100vh;
}
.home-container {
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.buffer {
  padding: 5px;
}
.ascii-art {
  font-family: monospace;
  white-space: pre-line;
  color: #b4c800;
}
.horizontal-container {
  display: flex;
  justify-content: space-around; /* Adjust as needed (space-between, space-evenly, etc.) */
}
.rounded-link {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.435);
  padding: 3px; /* Optional: Add padding for better visual appearance */
  color: black;
}
.bordered-div {
  border: 1px solid black;
  padding: 3px; /* Optional: Add padding for better visual appearance */
}
.App {
  background-color: #deedd3;
  min-height: 100vh;
}
.App-header {
  /* background-color: #deedd3;
  min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  /* font-size: calc(10px + 2vmin); */
  color: black;
}
.audio-desc {
  align-items: center;
  max-width: 300px;
}
.home-link {
  display: block;
  align-items: center;
  text-align: center;
  border: 1px solid black;
  padding: 3px;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button {
  border: none;
  background-color: #007bff00; /* Add your preferred background color */
  color: #fff; /* Add your preferred text color */
  padding: 8px 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0057b300; /* Add your preferred hover background color */
}
